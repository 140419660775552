import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";

const BulletinPage = (props) => {

  const data = useStaticQuery(graphql`
    query BulletinPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "bulletin"}}) {
        html
      }
    }
  `);
  const __html = data.markdownRemark.html;

  return (
    <Layout activeLink="/stay-informed" pageTitle="Bulletin" pageDescription="" {...props}>
      <div className="text-center pt-10">
        <EmphasisTextUnderlined strokeType="strokeSmallBlue" containerClassName="mx-auto" className="w-36"/>
        <p className="pt-10 mb-10 text-5xl font-bold text-blue-400">Bulletin</p>
      </div>

      <div className="px-20 pt-10">
      <div className="text-justify markdown-html" dangerouslySetInnerHTML={{__html}}/>
        </div>

      <HelpMethodsSection/>
    </Layout>
  );
};

BulletinPage.propTypes = {};

export default BulletinPage;
